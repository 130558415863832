body, html {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.App {
  flex: 1;
}

.App-header {
  flex-direction: column;
  align-items: center;
}

.logo img {
  height: 128px; /* Adjust size as needed */
  pointer-events: none;
}

.App {
  text-align: center;
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  flex-direction: column;
}

.App-logo {
  height: 5rem;
  animation: spin infinite 20s linear;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

h1 {
  font-size: 2.5rem;
}

footer {
  display: flex;
  font-size: small;
  justify-content: center;
  align-items: center;
  bottom: 0.1rem;
}

.links, .tools {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.links .link, .tools .tool {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #08f26e;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  background-color: #2b2b2b;
  border-radius: 5px;
  padding: 1vh 1vh;
}

.links .link:hover, .tools .tool:hover {
  opacity: 1;
}

.icony {
  padding-right: 5px;
}

.icon {
  width: 46px;
}

.link {
  height: 46px;
}

.tools {
  margin-block-end: 1em !important;
}

.tools .tool {
  color: #08cff2 !important;
}


/* calc */
/* Styles for the select container */
.sizes {
  justify-content: center;
}
.version-selector-container {
  margin-bottom: 20px;
  position: relative;
  display: inline-block;
  width: 200px;
}

/* Hide the default select dropdown arrow */
.version-selector {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #ffffff;
  border: 2px solid #dddddd;
  padding: 10px 40px 10px 10px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.version-selector-container:after {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #888888;
}

.version-selector:focus {
  outline: none;
  border-color: #555555;
}

.slider-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-grow: 0;
}

.slider-value {
  width: 10vh;
}

.world-size-calculator {
  width: 50vh;
}

.overworld-slider {
  flex-grow: 1;
  margin: 0 10px;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  border-radius: 5px;
  background: #4CAF50;
  outline: none;
  opacity: 0.8;
  transition: opacity .2s;
}

.nether-slider {
  flex-grow: 1;
  margin: 0 10px;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  border-radius: 5px;
  background: #703131;
  outline: none;
  opacity: 0.8;
  transition: opacity .2s;
}

.nether-slider:hover {
  opacity: 1;
}

.end-slider {
  flex-grow: 1;
  margin: 0 10px;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  border-radius: 5px;
  background: #dae09c;
  outline: none;
  opacity: 0.8;
  transition: opacity .2s;
}

.end-slider:hover {
  opacity: 1;
}

.overworld-slider:hover {
  opacity: 1;
}

/* Slider thumb */
.overworld-slider {
  -webkit-appearance: none; /* Removes default webkit styles */
}

.overworld-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default webkit styles */
  appearance: none;
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.overworld-slider::-moz-range-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.overworld-slider::-ms-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.nether-slider {
  -webkit-appearance: none; /* Removes default webkit styles */
}

.nether-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default webkit styles */
  appearance: none;
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.nether-slider::-moz-range-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.nether-slider::-ms-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.end-slider {
  -webkit-appearance: none; /* Removes default webkit styles */
}

.end-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Removes default webkit styles */
  appearance: none;
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.end-slider::-moz-range-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.end-slider::-ms-thumb {
  height: 25px; /* Set the size of the thumb */
  width: 25px;
  border-radius: 50%; /* Makes it a circle */
  background: #ddd; /* Light gray background */
  cursor: pointer; /* Cursor appears as a pointer when hovering over the thumb */
}

.slider-icon {
  height: 32px;
  width: 32px;
}